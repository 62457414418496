<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="answers"
      disable-pagination
      hide-default-footer
      class="elevation-1"
    ></v-data-table>
  </div>
</template>

<script>
export default {
  name: 'OnboardingAnswers',
  props: {
    answers: {
      type: [Array],
      default: () => []
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Question',
          align: 'start',
          sortable: false,
          value: 'question.name',
          width: '50%'
        },
        { text: 'Answer', value: 'answer', sortable: false, width: '50%' }
      ]
    }
  },

  mounted() {},

  computed: {},

  methods: {},

  watch: {
    answers(val) {}
  }
}
</script>
