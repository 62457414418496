<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'users' }"
          >Users</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4" v-if="form">
      <div class="display-2 font-weight-bold my-6 text-capitalize">
        {{ displayName }}
      </div>
      <div class="d-flex my-6">
        <v-tabs hide-slider class="user-info-tabs">
          <v-tab class="pa-0 mr-5" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-md-4 col-sm-12 col-xs-12 mt-6 pa-0">
            <v-alert
              dense
              outlined
              type="warning"
              color="orange darken-3"
              max-width="400px"
              v-if="!!user.blocked_at"
            >
              This account is <strong>disabled</strong>.
            </v-alert>

            <avatar-form
              :user="user"
              @change="avatarChange"
              @avatarRemove="userAvatarRemoved"
              :avatarLoading="avatarLoading"
            />

            <v-form ref="form" v-if="form" class="user-form">
              <v-divider> </v-divider>

              <h3 class="py-5 primary--text">User Information</h3>

              <label class="text-field-label">Firstname</label>
              <v-text-field
                flat
                solo
                required
                class="mt-2"
                v-model="form.first_name"
                :error-messages="form.$getError('first_name')"
                :loading="loading"
              ></v-text-field>

              <label class="text-field-label">Lastname</label>
              <v-text-field
                flat
                solo
                required
                class="mt-2"
                v-model="form.last_name"
                :error-messages="form.$getError('last_name')"
                :loading="loading"
              ></v-text-field>

              <label class="text-field-label">Email</label>
              <v-text-field
                flat
                solo
                required
                class="mt-2"
                v-model="form.email"
                :error-messages="form.$getError('email')"
                :loading="loading"
              >
                <!-- <v-tooltip slot="append" top v-if="user.email">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="success"
                      v-on="on"
                      v-if="user.email_verified"
                      >{{ icons.check }}</v-icon
                    >
                    <v-icon color="warning" v-on="on" v-else>{{
                      icons.alert
                    }}</v-icon>
                  </template>
                  <span v-if="user.email_verified">Email Verified</span>
                  <span v-else>Unverified email</span>
                </v-tooltip> -->
              </v-text-field>

              <label class="text-field-label">Birthdate</label>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    flat
                    solo
                    :append-icon="icons.calendar"
                    v-model="computedDateFormatted"
                    label="Birthdate"
                    v-bind="attrs"
                    @click:append="on.click"
                    @change="changeDate($event)"
                    :error-messages="form.$getError('birthdate')"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.birthdate"
                  :active-picker.sync="activePicker"
                  :max="
                    new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="1900-01-01"
                  @change="save"
                ></v-date-picker>
              </v-menu>

              <!-- <label class="text-field-label">Email Verification Code</label>
              <v-text-field
                flat
                solo
                required
                class="mt-2"
                v-model="form.email_verification_code"
                :error-messages="form.$getError('email_verification_code')"
                :loading="loading"
              >
              </v-text-field> -->

              <label class="text-field-label">Phone number</label>
              <v-row>
                <v-col lg="3" md="3" sm="4">
                  <v-select
                    :items="countryCode"
                    label="Code"
                    hide-details="auto"
                    flat
                    solo
                    required
                    item-value="value"
                    item-text="label"
                    class="mt-2"
                    v-model="selectedPhoneNumberPrefix"
                  >
                    <template v-slot:selection>
                      {{ `+${selectedPhoneNumberPrefix}` }}
                    </template>
                  </v-select>
                </v-col>
                <v-col lg="9" md="9" sm="8">
                  <v-text-field
                    flat
                    solo
                    required
                    class="mt-2"
                    :error-messages="form.$getError('phone_number')"
                    :loading="loading"
                    v-model="splittedPhoneNumber"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <div class="d-flex mb-4">
                <label class="text-field-label mr-4">Status: </label>
                <v-row>
                  <v-col lg="3" md="3" sm="4">
                    <Status :status="form.status" />
                  </v-col>
                </v-row>
              </div>

              <div class="d-flex mb-4" v-if="onboardedAt">
                <label class="text-field-label mr-4">Activation Date: </label>
                <v-row>
                  <v-col lg="12" md="3" sm="4">
                    {{ onboardedAt }}
                  </v-col>
                </v-row>
              </div>

              <v-divider class="mb-4"></v-divider>
              <v-row class="mb-3">
                <v-col>
                  <div class="d-flex justify-space-between align-center">
                    <div class="mr-auto">
                      <h4>
                        Medications
                      </h4>
                    </div>
                    <v-btn
                      small
                      color="primary"
                      @click="addNewMedication"
                      depressed
                    >
                      <v-icon left> {{ icons.add }}</v-icon> Add New
                    </v-btn>
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  v-for="(medication, key) in form.medications"
                  :key="key"
                >
                  <v-text-field
                    hide-details="auto"
                    :label="`Medication ${key + 1}`"
                    outlined
                    v-model="form.medications[key]"
                    :error-messages="form.$getError(`medications.${key}`)"
                    :loading="loading"
                  >
                    <template slot="append-outer">
                      <v-btn
                        v-if="form.medications.length > 1"
                        @click="deleteMedication(key)"
                        class="ml-2"
                        icon
                        dark
                        x-small
                        color="red"
                      >
                        <v-icon>
                          {{ icons.close }}
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-checkbox
                :input-value="user && user.blocked_at"
                label="Disable user account"
                color="orange darken-3"
                class="mt-0"
                @mousedown="showModal = true"
              ></v-checkbox>

              <div class="mt-2">
                <v-btn
                  color="error"
                  class="mr-4 px-6"
                  height="40px"
                  width="100%"
                  @click="deleteDialog = true"
                  >Delete</v-btn
                >
              </div>
              <div class="mt-4">
                <v-btn
                  color="primary"
                  class="mr-4 px-6"
                  height="40px"
                  width="100%"
                  @click="updateUserDetails"
                  :loading="form.$busy"
                  >Update</v-btn
                >
              </div>
            </v-form>

            <v-dialog v-model="deleteDialog" max-width="290">
              <v-card>
                <v-card-title class="headline">Delete ?</v-card-title>

                <v-card-text
                  >Are you sure you want to delete this account ?</v-card-text
                >

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn text @click="deleteDialog = false">Cancel</v-btn>

                  <v-btn
                    color="red"
                    text
                    @click="deleteUser"
                    :loading="isLoadingDelete"
                    >Yes, Delete please</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>

          <v-tab-item class="col-md-12 col-sm-12 col-xs-12 mt-6 p-4 pl-5">
            <OnboardingAnswers
              v-if="form.onboarding_answers"
              :answers="form.onboarding_answers"
            />
          </v-tab-item>

          <v-tab-item class="col-md-12 col-sm-12 col-xs-12 mt-6 p-4 pl-5">
            <ReassessmentAnswers :answers="form.reassessment_answers" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>

    <ConfirmModal
      v-model="showModal"
      title="Disable Account"
      :message="disableMessage"
      @cancel="showModal = false"
      @confirm="blockAccount"
    ></ConfirmModal>
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiCheck,
  mdiAlertCircleOutline,
  mdiPlus,
  mdiClose,
  mdiCalendar
} from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import AvatarForm from './components/AvatarForm'
import ConfirmModal from '@/components/modals/ConfirmModal'
import OnboardingAnswers from './components/answers/OnboardingAnswers'
import ReassessmentAnswers from './components/answers/ReassessmentAnswers'
import Status from './components/Status.vue'
import Form from '@/utils/form'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default {
  name: 'UserDetails',

  components: {
    AvatarForm,
    AppBarNavIcon,
    ConfirmModal,
    OnboardingAnswers,
    ReassessmentAnswers,
    Status
  },

  data() {
    return {
      activePicker: null,
      date: null,
      menu: false,
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
        add: mdiPlus,
        close: mdiClose,
        calendar: mdiCalendar
      },
      loading: false,
      avatarLoading: false,
      deleteDialog: false,
      form: null,
      showCropperModal: false,
      avatarTimestamp: new Date().getTime(),
      tabItems: [
        { tab: 'Info' },
        { tab: 'Onboarding Answers' },
        { tab: 'Reassessments' }
      ],
      snackbar: {
        show: false,
        message: null,
        color: ''
      },
      showModal: false,
      isLoadingDelete: false,
      countryCode: [
        { value: '63', label: 'Philippines' },
        { value: '61', label: 'Australia' }
      ],
      selectedPhoneNumberPrefix: '',
      splittedPhoneNumber: '',
      computedDateFormatted: null,
      onboardedAt: null
    }
  },

  computed: {
    ...mapState({
      user: state => state.user.userDetails,
      authUser: state => state.auth.user
    }),
    displayName() {
      return this.form.full_name || this.form.email
    },
    disableMessage() {
      const action = this.user && this.user.blocked_at ? 'unblock' : 'block'
      return `Are you sure you want ${action} this account?`
    }
    /*
    computedDateFormatted() {
      return this.formatDate(this.form.birthdate)
    }
    */
    /*
    computedDateFormatted: {
      get() {
        return this.formatDate(this.form.birthdate)
      },
      set(val) {}
    }
    */
  },

  methods: {
    ...mapActions({
      getUserDetails: 'user/getUserDetails',
      changeAvatar: 'user/changeAvatar',
      updateUser: 'user/updateUser',
      blockUser: 'user/blockUser'
    }),

    ...mapMutations({
      clearUserDetails: 'user/clearUserDetails',
      setSelectedUserAvatar: 'user/setSelectedUserAvatar',
      userAvatarRemoved: 'user/userAvatarRemoved'
    }),

    async getUser() {
      this.loading = true
      await this.getUserDetails(this.$route.params.id)
      this.form = new Form(this.user)
      this.computedDateFormatted = this.formatDate(this.form.birthdate)
      this.onboardedAt = this.user.onboarded_at
        ? dayjs(this.user.onboarded_at).format('YYYY-MM-DD')
        : ''
      this.loading = false
    },

    async avatarChange(image) {
      this.avatarLoading = true
      let data = new FormData()
      data.append('user_id', this.user.id)
      data.append('avatar', image)
      await this.changeAvatar(data)
      this.avatarLoading = false
      this.showSnackbar('Avatar successfully updated!', 'success')
    },

    async updateUserDetails() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.updateUser({
        ...this.form.$data(),
        phone_number: `${this.selectedPhoneNumberPrefix}${this.splittedPhoneNumber}`,
        medications: this.form.medications ?? []
      })
        .then(() => {
          this.form.$busy = false
          this.showSnackbar('User details successfully updated!', 'success')
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    deleteUser() {
      this.isLoadingDelete = true
      this.user.delete().then(() => {
        this.isLoadingDelete = false
        this.deleteDialog = false
        this.showSnackbar('User deleted successfully!')
        this.$router.replace('/users')
      })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    save(date) {
      this.$refs.menu.save(date)
    },

    blockAccount(value) {
      this.form.$busy = true
      const action = this.user.blocked_at ? 'unblocked' : 'blocked'
      this.blockUser({
        id: this.user.id,
        action
      })
        .then(() => {
          this.form.$busy = false
          this.showModal = false
          this.showSnackbar(`User successfully ${action}!`, 'success')
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    formatDate(date, format = 'DD/MM/YYYY') {
      return dayjs
        .utc(date)
        .local()
        .format(format)
    },

    changeDate(date) {
      dayjs.extend(customParseFormat)
      this.form.birthdate = dayjs(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
    },

    addNewMedication() {
      if (this.form.medications == null) this.form.medications = []

      this.form.medications.push('')
    },

    deleteMedication(key) {
      this.form.medications.splice(key, 1)
    }
  },

  created() {
    this.getUser()
  },

  destroyed() {
    this.clearUserDetails()
  },

  watch: {
    $route() {
      this.getUser()
    },
    'user.phone_number'(val) {
      const country = this.countryCode.find(country =>
        val.startsWith(country.value)
      )

      this.selectedPhoneNumberPrefix = country.value
    },
    'form.phone_number'(val) {
      this.splittedPhoneNumber = val.substr(
        this.selectedPhoneNumberPrefix.length,
        val.length
      )
    },
    'form.birthdate'(val) {
      this.computedDateFormatted = this.formatDate(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.user-form {
  width: 400px;

  @media (max-width: 420px) {
    width: 100%;
  }
}
</style>
