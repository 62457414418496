<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-form @submit.prevent="addUser" ref="form">
        <v-card>
          <v-card-title class="text-h5">Add User</v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    required
                    label="Firstname"
                    hide-details="auto"
                    v-model="form.first_name"
                    :error-messages="form.$getError('first_name')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    required
                    label="Lastname"
                    hide-details="auto"
                    v-model="form.last_name"
                    :error-messages="form.$getError('last_name')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    label="Email"
                    hide-details="auto"
                    v-model="form.email"
                    :error-messages="form.$getError('email')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="3" md="3" sm="4">
                  <v-select
                    :items="countryCode"
                    label="Code"
                    hide-details="auto"
                    outlined
                    required
                    item-value="value"
                    item-text="label"
                    v-model="selectedCountryCode"
                  >
                    <template v-slot:selection>
                      {{ `+${selectedCountryCode}` }}
                    </template></v-select
                  >
                </v-col>
                <v-col lg="9" md="9" sm="8">
                  <v-text-field
                    outlined
                    required
                    hide-details="auto"
                    label="Phone Number"
                    v-model="form.phone_number"
                    :error-messages="form.$getError('phone_number')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.tempBirthdate"
                        :append-icon="icons.calendar"
                        @click:append="on.click"
                        @change="changeDate($event)"
                        placeholder="dd/mm/yyyy"
                        label="Birthdate"
                        v-bind="attrs"
                        outlined
                        hide-details="auto"
                        :error-messages="form.$getError('birthdate')"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.birtdate"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1900-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="d-flex justify-space-between align-center">
                    <div class="mr-auto">
                      <h4>
                        Medications
                      </h4>
                    </div>
                    <v-btn
                      small
                      color="primary"
                      @click="addNewMedication"
                      depressed
                    >
                      <v-icon left> {{ icons.add }}</v-icon> Add New
                    </v-btn>
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  v-for="(medication, key) in form.medications"
                  :key="key"
                >
                  <v-text-field
                    hide-details="auto"
                    :label="`Medication ${key + 1}`"
                    outlined
                    v-model="form.medications[key]"
                    :error-messages="form.$getError(`medications.${key}`)"
                  >
                    <template slot="append-outer">
                      <v-btn
                        v-if="form.medications.length > 1"
                        @click="deleteMedication(key)"
                        class="ml-2"
                        icon
                        dark
                        x-small
                        color="red"
                      >
                        <v-icon>
                          {{ icons.close }}
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn @click="closeDialog" text>Cancel</v-btn>
              <v-btn type="submit" color="primary" :loading="form.$busy"
                >Submit</v-btn
              >
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" right>
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text color="green" v-bind="attrs" @click="snackbar.show = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Form from '@/utils/form'
import User from '@/models/User'
import { mdiCalendar, mdiClose, mdiPlus } from '@mdi/js'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: this.value,
      form: new Form({
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        birthdate: null,
        tempBirthdate: null,
        medications: ['']
      }),
      activePicker: null,
      date: null,
      menu: false,
      snackbar: {
        show: false,
        message: null
      },
      countryCode: [
        { value: '63', label: 'Philippines' },
        { value: '61', label: 'Australia' }
      ],
      selectedCountryCode: '61',
      icons: {
        add: mdiPlus,
        close: mdiClose,
        calendar: mdiCalendar
      }
    }
  },
  methods: {
    addUser() {
      const payload = {
        ...this.form.$data(),
        phone_number: `${this.selectedCountryCode}${this.form.$data()
          .phone_number ?? ''}`
      }

      this.form.$busy = true
      this.form.$clearErrors()
      new User(payload)
        .save()
        .then(() => {
          this.dialog = false
          this.showSnackbar('User was successfully added!')
          this.$refs.form.reset()
          this.form.medications = ['']
          this.form.$clearErrors()
          this.$emit('event')
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },
    changeDate(date) {
      dayjs.extend(customParseFormat)
      this.form.birthdate = dayjs(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
    },
    closeDialog() {
      this.dialog = false
      this.$refs.form.reset()
      this.form.medications = ['']
      this.form.$clearErrors()
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    showSnackbar(message) {
      this.snackbar.message = message
      this.snackbar.show = true
    },
    addNewMedication() {
      this.form.medications.push('')
    },
    deleteMedication(key) {
      this.form.medications.splice(key, 1)
    }
  },
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
      if (val) {
        this.selectedCountryCode = '61'
      }
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  }
}
</script>
